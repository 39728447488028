var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(0),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm.info.id))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(1),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm._f("readableDate")(_vm.info.createdAt)))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(2),_c('div',{},[_c('a',{attrs:{"href":("https://fb.com/" + (_vm.info.id)),"target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"icon ion-ios-link"})])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(3),_c('div',{},[_c('a',{attrs:{"href":("https://console.firebase.google.com/project/mujeeb-server/database/mujeeb-server/data/pages/" + (_vm.info.id)),"target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"icon ion-md-flame"})])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(4),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm._f("readableDate")(_vm.info.commenter_last_time_used)))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(5),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm.info.numOfCommenters))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(6),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm.info.package))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(7),_c('div',{},[_c('div',{staticClass:"rounded pl-3 pr-3 text-white",class:{
            'background-red': !_vm.info.valid,
            'background-green': _vm.info.valid
          }},[_c('p',[_vm._v(_vm._s(_vm.info.valid))])])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(8),_c('div',{},[_c('div',{staticClass:"rounded pl-3 pr-3 text-white",class:{
            'background-red':
              !_vm.loading_states.fetching_validity_state &&
              !_vm.accessToken_validity,
            'background-green':
              !_vm.loading_states.fetching_validity_state && _vm.accessToken_validity
          }},[(_vm.loading_states.fetching_validity_state)?_c('div',[_c('spring-spinner',{attrs:{"animation-duration":2000,"size":20,"color":'#00b1ff'}})],1):_c('p',[_vm._v(_vm._s(_vm.accessToken_validity ? "valid" : "NOT valid"))])])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(9),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm.messages_in_queue))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(10),_c('div',{},[_c('p',[_vm._v(_vm._s(_vm.comments_in_queue))])])]),_c('div',{staticClass:"flex justify-between content-center items-center p-3 shadow rounded mb-2"},[_vm._m(11),_c('div',{},[_c('div',{staticClass:"rounded pl-3 pr-3"},[(_vm.loading_states.whos_access_token)?_c('div',[_c('spring-spinner',{attrs:{"animation-duration":2000,"size":20,"color":'#00b1ff'}})],1):_c('p',[_vm._v(_vm._s(_vm.owner_of_access_token))])])])]),(_vm.chatbot_Exists)?_c('chatbot'):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("id:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("created at:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("link:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("database:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("last use date:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("# commenters:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("package:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("valid:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("access token:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("messages in queue:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("comments in queue:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-bold"},[_c('p',[_vm._v("whose access token:")])])}]

export { render, staticRenderFns }