<template>
  <div>
    <hr>
    <p class="text-center mt-12 mb-4 text-lg font-bold">Chatbot</p>
    <div
      class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
    >
      <div class="font-bold">
        <p>created at:</p>
      </div>
      <div class="">
        <p>{{ chatbot_info.created_at | readableDate }}</p>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
    >
      <div class="font-bold">
        <p>package:</p>
      </div>
      <div class="">
        <p>{{ chatbot_info.package }}</p>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
    >
      <div class="font-bold">
        <p>template:</p>
      </div>
      <div class="">
        <p>{{ chatbot_info.recipe }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {};
  },
  filters: {
    readableDate: function(someDate) {
      if (someDate) {
        return `${moment
          .utc(someDate)
          .format("MMM D YYYY")} | ${moment
          .utc()
          .diff(moment.utc(someDate), "days")} days`;
      } else {
        return "None";
      }
    }
  },
  computed: {
    chatbot_info() {
      return this.$store.getters["page/chatbot_info"];
    }
  }
};
</script>
<style lang="scss" scoped></style>
