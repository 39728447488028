<template>
</template>

<script>
export default {
  name: "callback",
  mounted() {
    this.$auth.handleAuthentication().then(() => {
      this.$router.push({ name: "home" });
    });
  }
};
</script>

<style>
</style>
