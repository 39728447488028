import { render, staticRenderFns } from "./Page_.vue?vue&type=template&id=99fb655c&scoped=true&"
import script from "./Page_.vue?vue&type=script&lang=js&"
export * from "./Page_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99fb655c",
  null
  
)

export default component.exports