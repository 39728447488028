import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";
import VuexPersist from 'vuex-persist';
Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 's-mujeeb', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

import page from "./page.store.module"

export default new Vuex.Store({
  modules: {
    page
  },
  state: {
    central_loading: false,
    platformVersions: [],
    pagesToBeChanges: [],
    validityState: true,
    query: null,
    useQuery: false,
    pages: [],
    firstInPage: null,
    lastInPage: null,
    limit: 10,
    Insights: [],
    criterion: "createdAt",
    endOfTheList: false,
    isFetchingPages: false,
    ErrorOccured: false,
    selectedPackage: "free",
    packages: [],
    criteria: [
      {
        buttonText: "users",
        criterion: "users"
      },
      {
        buttonText: "number of commenters",
        criterion: "numOfCommenters"
      },
      {
        buttonText: "number of broadcasts",
        criterion: "numOfBroadcasts"
      },
      {
        buttonText: "launch Date",
        criterion: "createdAt"
      },
      {
        buttonText: "commenter last time used",
        criterion: "commenter_last_time_used"
      },
      {
        buttonText: "broadcast last time used",
        criterion: "broadcast_last_time_used"
      }
    ],
    pageInfo: null
  },
  mutations,
  actions,
  getters,
  plugins: [vuexLocalStorage.plugin]
});
