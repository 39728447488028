<template>
  <div>
    <div class="flex justify-between content-center items-center p-3 mb-2">
      <div class="font-bold">
        <p>Page validity:</p>
      </div>
      <div class="">
        <b-switch
          v-model="page_validity"
          type="is-success"
          @input="confirmValidityChange"
        >
        </b-switch>
      </div>
    </div>

    <div class="flex justify-between content-center items-center p-3 mb-2">
      <div class="font-bold">
        <p>Change package:</p>
      </div>
      <div class="">
        <b-dropdown v-model="selectedPagePlan" @change="confirmPackageChange">
          <button class="button is-primary" slot="trigger">
            <span>{{ selectedPagePlan }}</span>
            <i class="ion-md-arrow-dropdown ml-2"></i>
          </button>
          <b-dropdown-item
            v-for="(p, index) in $store.getters.packages"
            :key="index"
            :value="p"
            >{{ p }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 mb-2 flex-wrap"
    >
      <div class="font-bold">
        <p>Page access token:</p>
      </div>
      <div class="">
        <b-field>
          <b-input ref="accesstoken_input" v-model="access_token" disabled>
          </b-input>
          <p class="control">
            <button class="button is-primary" @click="copy_access_token">
              <div v-if="processing_fetching_AT">
                <spring-spinner
                  :animation-duration="2000"
                  :size="20"
                  :color="'#ffffff'"
                ></spring-spinner>
              </div>
              <i v-else class="icon ion-md-copy"></i>
            </button>
          </p>
        </b-field>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 mb-2 flex-wrap"
    >
      <div class="font-bold">
        <p>Remove menue:</p>
      </div>
      <div class="">
        <button
          :disabled="processing_removing_profile"
          @click="remove_messenger_profile"
          class="custom_button flex justify-between align-center itmes"
        >
          Remove
          <spring-spinner
            class="ml-3"
            v-if="processing_removing_profile"
            :animation-duration="2000"
            :size="20"
            :color="'#ffffff'"
          ></spring-spinner>
        </button>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 mb-2 flex-wrap"
    >
      <div class="font-bold">
        <p>Subscribe Page :</p>
      </div>
      <div class="">
        <button
          :disabled="processing_subscribe_page"
          @click="subscribe_page"
          class="custom_button green flex justify-between align-center itmes"
        >
          Subscribe
          <spring-spinner
            class="ml-3"
            v-if="processing_subscribe_page"
            :animation-duration="2000"
            :size="20"
            :color="'#ffffff'"
          ></spring-spinner>
        </button>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 mb-2 flex-wrap"
    >
      <div class="font-bold">
        <p>Notify page:</p>
      </div>
      <div class="">
        <button
          @click="show_notification_modal = true"
          class="custom_button white flex justify-between align-center itmes"
        >
          notify
          <spring-spinner
            class="ml-3"
            v-if="processing_removing_profile"
            :animation-duration="2000"
            :size="20"
            :color="'#ffffff'"
          ></spring-spinner>
        </button>
      </div>
    </div>

    <notificationModal
      v-if="show_notification_modal"
      @close-modal="show_notification_modal = flase"
    ></notificationModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SpringSpinner } from "epic-spinners";
import notificationModal from "./../notificationModal";
export default {
  components: {
    "spring-spinner": SpringSpinner,
    notificationModal
  },
  data() {
    return {
      loading: false,
      processing_fetching_AT: true,
      processing_removing_profile: false,
      processing_subscribe_page: false,
      selectedPagePlan: this.$store.getters["page/info"].package,
      page_validity: this.$store.getters["page/info"].valid,
      access_token: "",
      show_notification_modal: false
    };
  },
  methods: {
    fetch_access_token() {
      this.processing_fetching_AT = true;
      this.$axios({
        url: `/pages/${this.$route.params.id}/access_token`,
        method: "get",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      })
        .then(({ data }) => {
          this.access_token = data.access_token;
        })
        .catch(error => {
          // notify user if not an admin
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          console.log(error);
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing_fetching_AT = false;
        });
    },
    copy_access_token() {
      const el = document.createElement("textarea");
      el.value = this.access_token;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$buefy.toast.open({
        duration: 4000,
        message: `access token has been copied to your clipboard`,
        position: "is-top",
        type: "is-success"
      });
      // todo notify user of the copy
    },
    confirmPackageChange(selectedPackage) {
      const originalPackage = this.$store.getters["page/info"].package;
      this.$buefy.dialog.confirm({
        title: "Changing the package",
        message: `Be careful, you are about to change the package of this page to ${selectedPackage}`,
        confirmText: "Change",
        type: "is-success",
        onConfirm: () => {
          this.loading = true;
          // Make put Request
          this.$axios({
            url: `/pages/${this.$route.params.id}/plan`,
            method: "put",
            headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
            data: {
              plan: selectedPackage
            },
            responseType: "json"
          })
            .catch(error => {
              // TODO set error in store to show it in the snackbar
              let message = error;
              if (error.response != null) {
                message = error.response.data;
              }
              this.$buefy.toast.open({
                duration: 4000,
                message: `${message}`,
                position: "is-top",
                type: "is-danger"
              });
              this.selectedPagePlan = originalPackage;
              throw error;
            })
            .then(() => {
              this.$store.dispatch("page/change_plan", selectedPackage);
              this.$buefy.toast.open({
                duration: 4000,
                message: `Done updating.`,
                position: "is-top",
                type: "is-success"
              });
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });

          // End of put request
        },
        onCancel: () => {
          this.selectedPagePlan = originalPackage;
        }
      });
    },
    confirmValidityChange(validity_value) {
      this.$buefy.dialog.confirm({
        title: "Changing the validity state",
        message: `Be careful, you are about to change the validity of this page to ${
          validity_value ? "valid" : "invalid"
        }`,
        cancelText: "Cancel",
        confirmText: "Change",
        type: "is-success",
        onConfirm: () => {
          this.loading = true;
          this.$axios({
            url: `/pages/${this.$route.params.id}/validity`,
            method: "put",
            headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
            data: {
              state: validity_value
            },
            responseType: "json"
          })
            .catch(error => {
              this.page_validity = this.$store.getters["page/info"].valid;
              let message = error;
              if (error.response != null) {
                message = error.response.data;
              }
              this.$buefy.toast.open({
                duration: 4000,
                message: `${message}`,
                position: "is-top",
                type: "is-danger"
              });
              throw error;
            })
            .then(() => {
              this.$store.dispatch("page/set_validity", validity_value);
              this.page_validity = validity_value;
              this.$buefy.toast.open({
                duration: 4000,
                message: `Done updating`,
                position: "is-top",
                type: "is-success"
              });
            })
            .catch(error => {
              console.log(error);
            });
        },
        onCancel: () => {
          this.page_validity = this.$store.getters["page/info"].valid;
        }
      });
    },
    remove_messenger_profile() {
      this.processing_removing_profile = true;
      this.$axios({
        url: `/messenger/${this.$route.params.id}/messenger_profile`,
        method: "delete",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      })
        .then(({ data }) => {
          console.log(data);
        })
        .catch(error => {
          // todo display an error message in a toast
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing_removing_profile = false;
        });
    },
    subscribe_page() {
      this.processing_subscribe_page = true;
      this.$axios({
        url: `/pages/${this.$route.params.id}/subscribe`,
        method: "put",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      })
        .then(({ data }) => {
          console.log(data);
        })
        .catch(error => {
          // todo display an error message in a toast
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing_subscribe_page = false;
        });
    }
  },
  computed: {
    ...mapGetters({ packages: "packages", info: "page/info" })
  },
  created() {
    this.fetch_access_token();
  }
};
</script>
<style lang="scss" scoped>
.custom_button {
  background-color: #f14668;
  border-color: transparent;
  color: #fff;
  border-width: 1px;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-top-width: 1px;
  border-right-color: transparent;
  border-right-width: 1px;
  border-bottom-color: transparent;
  border-bottom-width: 1px;
  border-left-color: transparent;
  border-left-width: 1px;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  position: relative;
  vertical-align: top;
  &.white {
    background: white;
    color: black;
    border: 1px rgb(164, 164, 164) solid;
  }
  &.green {
    background: #48c774;
    color: white;
    border: 1px rgb(164, 164, 164) solid;
  }
}
</style>
