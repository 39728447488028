<template>
  <div class="dashboard relative font-sans ">
    <listModal
      v-if="showSelectedPages"
      :pages="$store.getters.pagesToBeChanges"
      @closePagesListModal="
        () => {
          showSelectedPages = false;
        }
      "
    ></listModal>

    <notificationModal
      v-if="show_notification_modal"
      @close-modal="
        () => {
          this.show_notification_modal = false;
        }
      "
    ></notificationModal>

    <div class="action-menu-container  text-white">
      <div
        class="action-menu-container__trigger"
        @click="showActionMenu"
        v-on-clickaway="closeActionMenu"
      >
        <i
          v-if="$store.getters.pagesToBeChanges.length === 0"
          class="icon ion-md-more"
        ></i>
        <p v-else>{{ $store.getters.pagesToBeChanges.length }}</p>
      </div>
      <div
        class="action-menu-container__options-container"
        :class="{ toggle: !actionsMenueClicked }"
      >
        <div
          class="action-menu-container__option"
          @click="
            () => {
              showSelectedPages =
                !showSelectedPages &&
                $store.getters.pagesToBeChanges.length !== 0;
            }
          "
        >
          <p>Show selected pages</p>
        </div>
        <div
          class="action-menu-container__option"
          @click="
            () => {
              $store.commit('deselectPages');
            }
          "
        >
          <p>Clear selection</p>
        </div>
        <div class="action-menu-container__option" @click="sendNotification()">
          <p>Send notification</p>
        </div>
        <div
          class="action-menu-container__option"
          @click="changePagesValidityState(false)"
        >
          <p>Invliadate</p>
        </div>
        <div
          class="action-menu-container__option"
          @click="changePagesValidityState(true)"
        >
          <p>Validate</p>
        </div>
      </div>
    </div>

    <!-- ++++++++++++++++++++++++++++++++++++++++ tool bar ++++++++++++++++++++++++++++++++++++++++ -->

    <div class="tools-bar">
      <!-- Search pages -->
      <div class="m-control-field">
        <b-field>
          <b-autocomplete
            v-model="selectedPage"
            open-on-focus
            @select="openDetails"
            :loading="isFetchingSuggestions"
            :field="searchBy"
            type="search"
            placeholder="Search..."
            @input="fetchSuggestions"
            :data="suggestions"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img width="32" :src="`${props.option.picture}`" />
                </div>
                <div class="media-content is-size-7">
                  {{ props.option.name }}
                  <br />
                  <small> Id: {{ props.option.id }} </small>
                </div>
              </div>
            </template>
          </b-autocomplete>

          <p class="control">
            <b-dropdown v-model="searchBy">
              <button class="button" slot="trigger">
                <span>Filters</span>
                <i class="ion-md-arrow-dropdown ml-2"></i>
              </button>

              <b-dropdown-item
                v-for="(property, index) in properties"
                :key="index"
                :value="property"
                >{{ property }}</b-dropdown-item
              >
            </b-dropdown>
          </p>
        </b-field>
      </div>

      <!-- Sort By -->
      <div class="m-control-field">
        <b-field class="sort-by-controller" label="Sort by">
          <b-select placeholder="Select a name" v-model="selectedCriterion">
            <option
              v-for="(filter, index) in $store.getters.criteria"
              :key="index"
              :value="filter.criterion"
            >
              {{ filter.buttonText }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- package selector -->
      <div class="m-control-field">
        <b-field class="sort-by-controller" label="Package">
          <b-select placeholder="Select a package" v-model="selectedPackage">
            <option
              v-for="(packageName, index) in $store.getters.packages"
              :key="index"
              :value="packageName"
            >
              {{ packageName }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- validity selector -->
      <div class="m-control-field">
        <b-field class="sort-by-controller" label="Validity">
          <b-select
            placeholder="Validity State"
            v-model="selected_validity_state"
          >
            <option
              v-for="(state, index) in validityStates"
              :key="index"
              :value="state"
            >
              {{ state }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- limit selector -->
      <div class="m-control-field">
        <b-field class="sort-by-controller" label="Limit">
          <b-select placeholder="Select a limit" v-model="selectedLimit">
            <option
              v-for="(limit, index) in limits"
              :key="index"
              :value="limit"
            >
              {{ limit }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- action selector -->
      <div class="m-control-field">
        <b-field class="sort-by-controller" label="Action">
          <b-select
            placeholder="Action"
            :disabled="$store.getters.pagesToBeChanges.length === 0"
            @input="commitAction"
          >
            <option
              v-for="(action, index) in actions"
              :key="index"
              :value="action"
            >
              {{ action }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <!-- ++++++++++++++++++++++++++++++++++++++++ query section ++++++++++++++++++++++++++++++++++++++++ -->

    <div class="query-controller">
      <div class="m-control-field">
        <b-field label="use query">
          <b-switch v-model="useQuery" @input="useQueryChanged">
            {{ useQuery }}
          </b-switch>
        </b-field>
      </div>

      <div class="query-options-container">
        <!-- property to query by -->
        <div class="m-control-field">
          <b-field label="Field" class="controll-input">
            <b-select
              placeholder="Select a property"
              v-model="query.property"
              :disabled="!useQuery"
            >
              <option
                v-for="(option, index) in pagesProperty"
                :value="option"
                :key="index"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>

        <!-- query condition < > <= >= == -->
        <div class="m-control-field">
          <b-field label="Condition" class="controll-input">
            <b-select
              placeholder="Select a Condition"
              v-model="query.condition"
              :disabled="!useQuery"
            >
              <option
                v-for="(option, index) in conditions"
                :value="option"
                :key="index"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>

        <!-- query value -->
        <div class="m-control-field">
          <div v-if="query.property !== ''" class="controll-input">
            <b-field
              v-if="!dateData.includes(query.property)"
              label="Number"
              :disabled="!useQuery"
            >
              <b-input placeholder="Number" v-model="query.value" type="number">
              </b-input>
            </b-field>

            <b-field v-else label="Select a date">
              <b-datepicker
                :disabled="!useQuery"
                @input="dateSelectionHandler"
                placeholder="Select a date..."
                :readonly="false"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>

        <!-- button to apply the query -->
        <button
          class="button controll-input"
          :disabled="
            !useQuery || !query.property || !query.condition || !query.value
          "
          @click="applyQuery"
        >
          Apply
        </button>

        <span class="spacer"></span>
      </div>
    </div>

    <div v-if="dataSet[0].values.length > 0" class="page-chart-contaier">
      <vue-frappe
        id="page-usage-chart"
        :labels="[
          'كانون الثاني',
          'شباط',
          'آذار',
          'نيسان',
          'أيار',
          'حزيران',
          'تموز',
          'آب',
          'أيلول',
          'تشرين الأول',
          'تشرين الثاني',
          'كانون الاول'
        ]"
        type="bar"
        :colors="['#00D67F']"
        hight="200"
        :dataSets="dataSet"
        title="Number of registered pages"
      ></vue-frappe>
    </div>

    <!-- ++++++++++++++++++++++++++++++++++++++++ insights ++++++++++++++++++++++++++++++++++++++++ -->

    <div class="insights">
      <!-- page registered this month -->
      <div class="insights-box shadow-md">
        <p>Pages registered this month</p>
        <p>
          {{ $store.getters.Insights.totlaThisMonth }} |
          {{ $store.getters.Insights.withMoreThan4Commenters }}
        </p>
      </div>
      <!-- page registered this year -->
      <div class="insights-box shadow-md">
        <p>Number of bots</p>
        <p>{{ $store.getters.Insights.numberOfBots }}</p>
      </div>
    </div>

    <!-- ++++++++++++++++++++++++++++++++++++++++ loading and table section ++++++++++++++++++++++++++++++++++++++++ -->

    <!-- loading component -->
    <!-- use global loading property -->
    <div class="loading-component" v-if="central_loading">
      <fingerprint-spinner
        :animation-duration="1000"
        :size="150"
        :color="'#00b1ff'"
      ></fingerprint-spinner>
    </div>

    <!-- table -->
    <div v-else class="Rtable Rtable--8cols Rtable--collapse Rtable--collapse">
      <div class="Rtable-row Rtable-row--header Rtable-row--8cols">
        <div class="Rtable-cell Rtable-cell--head"></div>
        <div class="Rtable-cell Rtable-cell--head">Name</div>
        <div class="Rtable-cell Rtable-cell--head">Created at</div>
        <div class="Rtable-cell Rtable-cell--head">#commenters</div>
        <div class="Rtable-cell Rtable-cell--head">Commenter LTU</div>
        <div class="Rtable-cell Rtable-cell--head">#Broadcasts</div>
        <div class="Rtable-cell Rtable-cell--head">Broadcast LTU</div>
        <div class="Rtable-cell Rtable-cell--head">Valid</div>
        <div class="Rtable-cell Rtable-cell--head"></div>
      </div>

      <div
        v-for="(page, index) in $store.getters.pages"
        :key="index"
        class="Rtable-row Rtable-row--8cols"
        :class="{
          selected: $store.getters.pagesToBeChanges.indexOf(page.id) !== -1,
          'glwoing-green': true
        }"
      >
        <div class="Rtable-cell" data="Select:">
          <input
            type="checkbox"
            @input="selectPage(page)"
            :checked="isChecked(page.id)"
          />
        </div>
        <!-- <div class="Rtable-cell" data="Select:"><p-check name="check" color="success" @input="selectPage(page)" :checked="isChecked(page.id)">select</p-check></div> -->
        <div class="Rtable-cell" data="Paeg Name:">
          <a :href="page.link" target="_blank" class="no-underline">{{
            page.name | truncate
          }}</a>
        </div>
        <div class="Rtable-cell" data="Created At:">
          {{ parseTime(page.createdAt) }}
        </div>
        <div class="Rtable-cell" data="Number Of Commenters:">
          {{ page.numOfCommenters }}
        </div>
        <div class="Rtable-cell" data="Date Of Last Commenter Acctivated:">
          {{ parseTime(page.commenter_last_time_used) }}
        </div>
        <div class="Rtable-cell" data="Number Of Broadcasts:">
          {{ page.numOfBroadcasts }}
        </div>
        <div class="Rtable-cell" data="Date Of Last Broadcast:">
          {{ parseTime(page.broadcast_last_time_used) }}
        </div>
        <div
          class="Rtable-cell"
          :class="[page.valid ? 'valid' : 'invalid']"
          data="Valid:"
        >
          {{ page.valid ? "valid" : "invalid" }}
        </div>
        <!-- <router-link :to="`page/${page.id}`" tag="div" class="Rtable-cell settings" data="Settings:"><i class="icon ion-ios-settings text-xl"></i></router-link> -->
        <a
          :href="`${_window.location.origin}/page/${page.id}`"
          target="blank"
          class="Rtable-cell settings"
          data="Settings:"
          ><i class="icon ion-ios-settings text-xl"></i
        ></a>
        <!-- <a :href="`http://localhost:8080/page/${page.id}`" target="blank" class="Rtable-cell settings" data="Settings:"><i class="icon ion-ios-settings text-xl"></i></a> -->
      </div>

      <div class="pagination-control flex justify-center mb-8">
        <button
          class="bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded-l"
          @click="
            $store.dispatch('previousPage', { accessToken: $auth.accessToken })
          "
        >
          <i class="icon ion-ios-arrow-back"></i>
          Prev
        </button>
        <button
          class="bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded-r"
          @click="
            $store.dispatch('nextPages', { accessToken: $auth.accessToken })
          "
        >
          Next
          <i class="icon ion-ios-arrow-forward"></i>
        </button>
      </div>
    </div>

    <!-- <TableComponent :packageName="selectedPackage"></TableComponent> -->
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import moment from "moment";
import debounce from "debounce";
import { mapGetters } from "vuex";
import { FingerprintSpinner } from "epic-spinners";
import listModal from "./utility components/listModalComponent.vue";
import notificationModal from "./../components/notificationModal.vue";
export default {
  components: {
    "fingerprint-spinner": FingerprintSpinner,
    listModal,
    notificationModal
  },
  mixins: [clickaway],
  data() {
    let _window = window;
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _window,
      show_notification_modal: false,
      dataSet: [
        {
          name: "Number of registered pages",
          chartType: "bar",
          values: []
        }
      ],
      showSelectedPages: false,
      actionsMenueClicked: false,
      selected_validity_state: true,
      validityStates: ["valid", "invalid"],
      actions: ["disable", "enable"],
      useQuery: false,
      selectedCriterion: "createdAt",
      selectedPackage: "free",
      properties: ["name", "id"],
      // selectedPages: [],
      searchBy: "name",
      suggestions: [],
      selectedPage: null,
      isFetchingSuggestions: false,
      pagesProperty: [
        "createdAt",
        "commenter_last_time_used",
        "broadcast_last_time_used",
        "numOfCommenters"
      ],
      date: null,
      query: {
        property: "",
        condition: "",
        value: null
      },
      conditions: [">", "<", ">=", "<=", "==="],
      dateData: [
        "createdAt",
        "commenter_last_time_used",
        "broadcast_last_time_used"
      ],
      limits: [10, 15, 20, 25],
      selectedLimit: 10
    };
  },
  mounted() {
    this.$store.dispatch("fetchPages", { accessToken: this.$auth.accessToken });
    this.$store.dispatch("fetchPackages", {
      accessToken: this.$auth.accessToken
    });
    this.$store.dispatch("fetchPagesInsights", {
      accessToken: this.$auth.accessToken
    });
    this.selectedCriterion = this.$store.getters.criterion || "createdAt";
    this.selectedPackage = this.$store.getters.selectedPackage || "free";
    this.selectedLimit = this.$store.getters.limit || 10;
    this.selected_validity_state = this.$store.getters.validityState || true;
    this._window = window;
    this.$axios({
      url: `/insights/year/${moment()
        .utc()
        .year()}/pages/histogram`,
      baseURL: process.env.VUE_APP_OLD_BASE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${this.$auth.accessToken}`
      },
      responseType: "json"
    })
      .then(({ data }) => {
        console.log(data);
        this.dataSet[0].values = [...Array(12).keys()].reduce((acc, month) => {
          if (typeof data[month] === "undefined") {
            acc[month] = 0;
          } else {
            acc[month] = data[month];
          }
          return acc;
        }, []);
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    /**
     * component based
     */
    parseTime(rawDate) {
      return `${moment(rawDate).format("MMM D YYYY")} | ${moment
        .utc()
        .diff(moment.utc(rawDate), "days")} days`;
    },
    fetchSuggestions: debounce(function(query) {
      console.log(query);
      // this.query = query
      if (query !== "") {
        this.autocomplete(query);
      }
    }, 1500),
    autocomplete(query) {
      this.isFetchingSuggestions = true;
      const options = {
        url: `/pages/by/${this.searchBy}`,
        baseURL: process.env.VUE_APP_OLD_BASE_URL,
        method: "get",
        params: {
          propertyvalue: query
        },
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      };
      this.$axios(options)
        .then(({ data }) => {
          this.suggestions = data ? data : [];
          this.isFetchingSuggestions = false;
        })
        .catch(error => {
          this.isFetchingSuggestions = false;
          console.log(error);
          this.notifyUser();
        });
    },
    notifyUser(msg = "Something went wrong", type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    openDetails(page) {
      this.$router.push({ name: "Page", params: { id: page.id } });
    },
    applyQuery() {
      this.$store.commit("setQuery", this.query);
      this.$store.dispatch("fetchPages", {
        accessToken: this.$auth.accessToken
      });
    },
    useQueryChanged(newValue) {
      this.$store.commit("setUseQuery", newValue);
    },
    dateSelectionHandler(d) {
      this.query.value = moment(d)
        .utc()
        .valueOf();
    },
    selectPage(selectedPage) {
      const previouslySelected = this.$store.getters.pagesToBeChanges.reduce(
        (acc, page, index) => {
          if (page.id === selectedPage.id) {
            acc = {};
            acc.index = index;
          }
          return acc;
        },
        false
      );
      if (!previouslySelected) {
        // this.selectedPages.push({id: selectedPage.id, name: selectedPage.name})
        this.$store.commit("pushToPagesToBeChanges", {
          id: selectedPage.id,
          name: selectedPage.name
        });
      } else {
        // this.selectedPages.splice(previouslySelected.index, 1)
        this.$store.commit(
          "removeItemFromPagesToBeChanges",
          previouslySelected.index
        );
      }
    },
    commitAction(action) {
      console.log(action);
    },
    showActionMenu() {
      this.actionsMenueClicked = !this.actionsMenueClicked;
    },
    closeActionMenu() {
      this.actionsMenueClicked = false;
    },
    changePagesValidityState(newValidityState) {
      // TODO call the sever to validate or invalidate the pages.
      if (this.$store.getters.pagesToBeChanges.length !== 0) {
        const options = {
          url: `/pages/validitystate`,
          baseURL: process.env.VUE_APP_OLD_BASE_URL,
          method: "post",
          data: {
            state: newValidityState,
            pagesIds: this.$store.getters.pagesToBeChanges.map(page => page.id)
          },
          headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
          responseType: "json"
        };
        this.$axios(options)
          .then(({ data }) => {
            if (data.success) {
              this.notifyUser(
                "validity states have been updated for the selected pages",
                "is-success"
              );
            } else {
              this.notifyUser();
            }
          })
          .catch(error => {
            console.log(error);
            this.notifyUser();
          });
      }
    },
    closePagesListModal() {
      console.log("closing the modal");
    },
    isChecked(id) {
      return this.$store.getters.pagesToBeChanges.reduce((acc, page) => {
        return page.id === id ? true : acc;
      }, false);
    },
    sendNotification() {
      console.log(JSON.stringify(this.$store.getters.pagesToBeChanges));
      // let pagesIds = this.$store.getters.pagesToBeChanges.map(({id}) => id)
      this.show_notification_modal = true;
    }
  },
  computed: {
    ...mapGetters(["central_loading"])
  },
  watch: {
    selectedCriterion(newCriterion, oldCriterion) {
      console.log(newCriterion);
      if (newCriterion !== oldCriterion) {
        this.$store.commit("setCriterion", newCriterion);
        this.$store.dispatch("fetchPages", {
          accessToken: this.$auth.accessToken
        });
      }
    },
    selectedPackage(newPcakge, oldPackage) {
      console.log(newPcakge);
      if (newPcakge !== oldPackage) {
        console.log(newPcakge);
        this.$store.commit("setSelectedPackage", newPcakge);
        this.$store.dispatch("fetchPages", {
          accessToken: this.$auth.accessToken
        });
      }
    },
    "$store.getters.ErrorOccured": function(errorOccured) {
      if (errorOccured) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Something went wrong.`,
          position: "is-bottom",
          type: "is-danger"
        });
      }
    },
    selectedLimit(newValue) {
      this.$store.commit("setLimit", newValue);
      this.$store.dispatch("fetchPages", {
        accessToken: this.$auth.accessToken
      });
    },
    selected_validity_state(newValue) {
      this.$store.commit("setValidityState", newValue === "valid");
      this.$store.dispatch("fetchPages", {
        accessToken: this.$auth.accessToken
      });
    }
  },
  filters: {
    truncate: function(name) {
      if (name.length > 23) {
        return `... ${name.slice(0, 24)}`;
      } else {
        return name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~sass_styles/_variables.scss";

.list-componenet {
  font-size: 100px;
}

.dashboard {
  word-break: break-all;
  min-height: 100vh;
  min-width: 300px;
  overflow-y: auto;
}

.no-usage,
.using {
  padding: 3px 10px;
  color: white;
}

.no-usage {
  background: $red;
}

.using {
  background: $green;
}

.tools-bar {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  justify-content: space-between;
  align-content: center;
  align-items: flex-end;
  padding: 0 50px;
}

.field:not(:last-child) {
  margin: 0 !important;
}

.mujeeb-pages {
  margin-top: 50px;
}

.pagination-control {
  margin: 50px auto;
}

.settings {
  cursor: pointer;
}

.insights {
  margin: 25px 0 25px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.insights-box {
  display: flex;
  width: 300px;
  height: 125px;
  border-radius: 5px;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.insights-box > p {
  font-size: 18px;
  font-weight: bold;
}

.insights-box > p:nth-child(2) {
  font-size: 25px;
  font-weight: bold;
}

.packages {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.package {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.query-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.query-controller {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 40px;
}

.query-switch {
  margin-bottom: 20px !important;
}

.query-options-container {
  .m-control-field {
    margin-right: 20px;
  }
}

.page-chart-contaier {
  width: 90%;
  margin: 30px auto;
}

@media screen and (max-width: 1100px) {
  .tools-bar {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    align-content: flex-start;
    padding: 40px;
  }

  .query-options-container {
    .m-control-field {
      margin-right: 0;
    }
  }

  .pages-wraper {
    display: flex;
    flex-wrap: wrap;
  }

  .insights-box {
    margin: 30px;
  }
}
</style>
