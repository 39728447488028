<template>
    <div class="m-snackbar">
      {{ text }}
    </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '~sass_styles/_variables.scss';
.m-snackbar {
  position: fixed;
  height: 50px;
  max-width: 600px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  bottom: 30px;
  left: 30px;
  color: white;
  background: $mujeebBlue;
  padding: 10px;
  min-width: 300px;
  border-radius: 6px;
}

</style>
