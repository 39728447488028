<template>
  <div id="app">
    <navigationHeader></navigationHeader>
    <router-view />
  </div>
</template>

<script>
import navigationHeader from './views/navigation'
import './assets/css/main.css'
import './assets/css/shared.scss'
import './assets/css/buefy_custom.scss'
import "./assets/scss/global_properties.scss";
import 'normalize.css'
export default {
  name: "App",
  components: {
    navigationHeader
  }
};
</script>

<style>
</style>

