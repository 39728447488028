import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import auth from "./auth0/auth";
import Buefy from "buefy";
import axios from "./../plugins/axios-plugin";
import VueFrappe from "vue2-frappe";
import navigationHeader from "./views/navigation.vue";
import getAtPath from "../plugins/path.plugin";
Vue.use(getAtPath);

Vue.use(auth);
Vue.use(Buefy);
Vue.use(axios);
Vue.use(VueFrappe);
Vue.component("navigationHeader", navigationHeader);
// Vue.component("p-check", PrettyCheck);
Vue.config.productionTip = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
