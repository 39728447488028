<template>
  <div>
    <div
      v-if="fetching_users"
      class="flex justify-center items-center align-items pt-6"
    >
      <SpringSpinner
        :animation-duration="2000"
        :size="120"
        :color="'#00b1ff'"
      ></SpringSpinner>
    </div>

    <div v-else>
      <div
        v-for="(user, user_id) in users"
        :key="user_id"
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2 flex-wrap"
      >
        <div
          class="font-bold px-4 py-1 rounded background-green text-white"
          :class="{ 'background-red': !user.validity }"
        >
          <p>{{ user.name }}</p>
        </div>
        <div class="flax flex-row">
          <b-button type="is-primary mr-1" @click="use_access_tokenn(user.id)">
            <p v-if="!loading || using_user !== user.id">use access token</p>
            <spring-spinner
              v-else-if="using_user === user.id"
              :animation-duration="2000"
              :size="20"
              :color="'#ffffff'"
            ></spring-spinner>
          </b-button>
          <b-button type="is-danger" @click="reset_user(user)">
            <p v-if="!processing_reset">reset</p>
            <spring-spinner
              v-else
              :animation-duration="2000"
              :size="20"
              :color="'#ffffff'"
            ></spring-spinner>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FingerprintSpinner } from "epic-spinners";
import { SpringSpinner } from "epic-spinners";
export default {
  components: {
    FingerprintSpinner,
    SpringSpinner
  },
  name: "users",
  data() {
    let users = [];
    try {
      users = this.$store.getters["page/info"].users;
      users = Object.values(users).reduce((acc, user_data) => {
        acc[user_data.id] = user_data;
        return acc;
      }, {});
    } catch (error) {
      console.log("error while parsing users object");
    }
    return {
      fetching_users: true,
      loading: false,
      users: users,
      processing_reset: false,
      using_user: null
    };
  },
  computed: {
    users_ids() {
      return Object.keys(this.users);
    }
  },
  methods: {
    check_users() {
      this.fetching_users = true;
      this.$axios({
        url: `/pages/${this.$route.params.id}/check_users`,
        method: "get",
        params: {
          users: this.users_ids
        },
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      })
        .catch(error => {
          // ToDo show error message fot the user
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          console.log(error);
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
          throw error;
        })
        .then(({ data }) => {
          // data.validity -> Object
          // item: { user_id {string}: validity {boolean} }
          const users_ids = Object.keys(data.validity);
          for (const user_id of users_ids) {
            this.$set(this.users[user_id], `validity`, data.validity[user_id]);
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.fetching_users = false;
        });
    },
    use_access_tokenn(user) {
      this.using_user = user;
      this.loading = true;
      const options = {
        url: `/pages/${this.$route.params.id}/access_token`,
        method: "put",
        params: {
          user_id: user.split("|")[1]
        },
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      };
      this.$axios(options)
        .then(response => {
          if (response.status === 200) {
            // todo show success message
            this.$buefy.toast.open({
              duration: 4000,
              message: `Done`,
              position: "is-top",
              type: "is-success"
            });
          } else {
            // show error message
            this.$buefy.toast.open({
              duration: 4000,
              message: `${user.name} no log has access to this page`,
              position: "is-top",
              type: "is-success"
            });
          }
        })
        .catch(error => {
          // ToDo show error message fot the user
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          console.log(error);
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
          throw error;
        })
        .finally(() => {
          // setTimeout(() => {
          //   this.loading = false;
          //   this.snackbarText = "";
          // }, 5000);
          this.using_user = null;
          this.loading = false;
        });
    },
    reset_user(user) {
      this.$buefy.dialog.confirm({
        title: "Resetting this user",
        message: `Be careful, you are about to reset ${user.name} `,
        confirmText: "Reset",
        type: "is-success",
        onConfirm: () => {
          this.processing_reset = true;
          const user_id = user.id.split("|")[1];
          const options = {
            url: `/pages/${this.$route.params.id}/users/${user_id}`,
            method: "delete",
            params: {
              user_id: user_id
            },
            headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
            responseType: "json"
          };
          this.$axios(options)
            .then(() => {})
            .catch(error => {
              // ToDo show error message fot the user
              let message = error;
              if (error.response != null) {
                message = error.response.data;
              }
              console.log(error);
              this.$buefy.toast.open({
                duration: 4000,
                message: `${message}`,
                position: "is-top",
                type: "is-danger"
              });
              throw error;
            })
            .finally(() => {
              this.processing_reset = false;
            });
        }
      });
    }
  },
  created() {
    this.check_users();
  }
};
</script>
<style lang="scss" scoped></style>
