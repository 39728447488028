export default {
  pages: state => state.pages,
  firstInPage: state => state.firstInPage,
  lastInPage: state => state.lastInPage,
  limit: state => state.limit,
  criterion: state => state.criterion,
  endOfTheList: state => state.endOfTheList,
  criteria: state => state.criteria,
  isFetchingPages: state => state.isFetchingPages,
  ErrorOccured: state => state.ErrorOccured,
  packages: state => state.packages,
  selectedPackage: state => state.selectedPackage,
  Insights: state => state.Insights,
  useQuery: state => state.useQuery,
  query: state => state.query,
  validityState: state => state.validityState,
  pagesToBeChanges: state => state.pagesToBeChanges,
  platformVersions: state => state.platformVersions,
  pageInfo: state => state.pageInfo,
  services: state => state.pageInfo.services,
  central_loading: state => state.central_loading
};
