<template>
  <div class="bg-white border rounded-lg overflow-hide post_card m-3 shadow">
    <div class="post-content overflow-hidden rounded-t-lg relative">
      <div class="absolute goto p-2 bg-white rounded-full hover:shadow border">
        <a target="_balnck" :href="post_data.postUrl">
          <i class="icon ion-md-open"></i>
        </a>
        <a
          class="ml-1"
          target="_balnck"
          :href="
            `https://console.firebase.google.com/u/0/project/mujeeb-server/database/mujeeb-server/data/posts/${
              post_data.postId
            }`
          "
        >
          <i class="icon ion-md-flame"></i>
        </a>
      </div>
      <img
        class="w-full object-scale-down"
        v-if="post_data.postContent.image"
        :src="post_data.postContent.image"
        alt=""
      />
      <div v-else class="flex justify-center align-center items-center h-full">
        <span class="leading-tight p-3">{{
          post_data.postContent.message
        }}</span>
      </div>
    </div>
    <div
      class="p-6 rounded-b-lg"
      :class="{ 'background-red-stopped': !post_data.enabled }"
    >
      <div class="text-gray-600 text-sm font-semibold tracking-wide uppercase">
        {{ post_data.date | readableDate }}
      </div>
      <div class="mt-2">
        <p
          v-if="post_data.intents.default"
          class="badge cursor-pointer default-reply relative background-green text-white px-2 inline-block rounded-full text-sm mr-1"
        >
          Default reply
          <span class="reply-text absolute p-3 border shadow rounded-lg">{{
            post_data.intents.default.message.type === "textTemplate"
              ? post_data.intents.default.message.data.message
              : "NOT TEXT MESSAGE"
          }}</span>
        </p>
        <p
          v-if="post_data.intents.customIntents"
          class="custom-intents relative badge background-green text-white px-2 inline-block rounded-full text-sm "
        >
          Custom reply
          <span class="key-words p-3 border shadow rounded-lg">
            <span
              class="rounded-full key-word"
              v-for="keyword in key_words(post_data.intents.customIntents)"
              :key="keyword"
              >{{ keyword }}</span
            >
          </span>
        </p>
      </div>
      <div class="relative mt-3" style="bottom: 0;">
        <b-button expanded @click="unlimit(post_data.postId)">
          <p v-if="!loading">Unlimit this post</p>
          <SpringSpinner
            v-else
            :animation-duration="2000"
            :size="20"
            :color="'#00b1ff'"
          ></SpringSpinner>
        </b-button>
        <b-button
          class="mt-2"
          expanded
          @click="scrape_comments(post_data.postId)"
        >
          <p v-if="!loading">Scrape Comments</p>
          <SpringSpinner
            v-else
            :animation-duration="2000"
            :size="20"
            :color="'#00b1ff'"
          ></SpringSpinner>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SpringSpinner } from "epic-spinners";
export default {
  props: {
    post_data: Object
  },
  components: {
    SpringSpinner
  },
  data() {
    console.log(this.post_data);
    return {
      loading: false
    };
  },
  filters: {
    readableDate: function(someDate) {
      if (someDate) {
        return `${moment.utc(someDate).format("MMM D YYYY - h:m a ")}`;
      } else {
        return "None";
      }
    }
  },
  methods: {
    unlimit(post_id) {
      this.loading = true;
      this.$axios({
        url: `/posts/${post_id}/unlimit`,
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        method: "put",
        responseType: "json"
      })
        .then(({ data }) => {
          if (data.success) {
            this.$buefy.toast.open({
              duration: 4000,
              message: `Done`,
              position: "is-top",
              type: "is-success"
            });
          }
        })
        .catch(error => {
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scrape_comments(post_id) {
      this.loading = true;
      this.$axios({
        url: `/${post_id}`,
        baseURL: "https://commenter.services.mujeeb.ai/scrape",
        method: "get",
        responseType: "text"
      })
        .then(() => {
          this.$buefy.toast.open({
            duration: 4000,
            message: `Done`,
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    key_words(intents) {
      let keywords = [];
      for (const intent of intents) {
        keywords = [...keywords, ...intent.replyTo];
      }
      return keywords;
    }
  }
};
</script>
<style lang="scss" scoped>
.bg-teal-500 {
  background-color: #38b2ac;
}
.post_card {
  width: 280px;
  .key-words {
    display: none;
    position: absolute;
  }
  .reply-text {
    display: none;
    width: 250px;
    z-index: 10;
    background: white;
    bottom: 110%;
    color: black;
  }
  .default-reply:hover {
    .reply-text {
      display: block;
      direction: rtl;
    }
  }
  .custom-intents:hover {
    .key-words {
      display: block;
      max-width: 250px;
      bottom: 110%;
      right: -100%;
      background: white;
      color: rgb(47, 47, 47);
      .key-word {
        padding: 1px 10px;
        background: rgb(184, 184, 184);
        color: white;
        margin: 2px;
        display: inline-block;
      }
    }
  }
  .post-content {
    height: 200px;
    .goto {
      top: 5%;
      right: 5%;
    }
  }
  .badge {
    line-height: 1.6rem;
  }
}
</style>
