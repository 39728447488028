export default {
  setPages(state, pages) {
    state.pages = pages;
  },
  setfirstInPage(state) {
    // state.firstInPage = firstInPage;
    state.firstInPage = state.useQuery
      ? state.pages[0][state.query.property]
      : state.pages[0][state.criterion];
  },
  setlastInPage(state) {
    // state.lastInPage = lastInPage;
    state.lastInPage = state.useQuery
      ? state.pages[state.pages.length - 1][state.query.property]
      : state.pages[state.pages.length - 1][state.criterion];
  },
  setCriterion(state, criterion) {
    state.criterion = criterion;
  },
  setEndOfTheList(state, endOfTheList) {
    state.endOfTheList = endOfTheList;
  },
  setIsFetchingPages(state, isFetchingPages) {
    state.isFetchingPages = isFetchingPages;
  },
  setError(state, ErrorOccured) {
    state.ErrorOccured = ErrorOccured;
  },
  setPackages(state, packages) {
    state.packages = packages;
  },
  setInsights(state, Insights) {
    state.Insights = Insights;
  },
  setQuery(state, query) {
    state.query = query;
  },
  setSelectedPackage(state, selectedPackage) {
    state.selectedPackage = selectedPackage;
  },
  setUseQuery(state, useQuery) {
    state.useQuery = useQuery;
  },
  setLimit(state, limit) {
    state.limit = limit;
  },
  setValidityState(state, validityState) {
    state.validityState = validityState;
  },
  setPagesToBeChanges(state, pagesToBeChanges) {
    state.pagesToBeChanges = pagesToBeChanges;
  },
  pushToPagesToBeChanges(state, newItem) {
    state.pagesToBeChanges.push(newItem);
  },
  removeItemFromPagesToBeChanges(state, index) {
    state.pagesToBeChanges.splice(index, 1);
  },
  deselectPages(state) {
    state.pagesToBeChanges = [];
  },
  platformVersions(state, platformVersions) {
    state.platformVersions = platformVersions;
  },
  setPageInfo(state, page) {
    state.pageInfo = page;
  },
  resetPageInfo(state) {
    state.pageInfo = null;
  },
  set_central_loading(state, payload) {
    state.central_loading = payload;
  }
};
