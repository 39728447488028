<template>
    <div class="m-modal">
      <div class="m-container">

        <ul class="m-container__itemsList">
          <li v-if="pages.length !== 0" v-for="(page, index) in pages" :key="index" class="itemsList__item">
            {{page.name}}
            <i class="icon ion-md-close" @click="$store.commit('removeItemFromPagesToBeChanges', page.index)"></i>
          </li>
          <li v-else>No pages were selected.</li>
        </ul>

        <div class="m-container__actions">
          <button @click="$emit('closePagesListModal')">close</button>
        </div>

      </div>

    </div>
</template>

<script>
export default {
  props: ['pages']

}
</script>

<style lang="scss" scoped>
@import '~sass_styles/_mixins.scss';
@import '~sass_styles/_variables.scss';

  .m-container__itemsList {
    max-height: 500px;
    overflow-y: auto;
    
    .itemsList__item {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      background: $mujeebBlueLight;
      border-radius: $border-radius-md;
      padding: 3px;
      margin-bottom: 5px;
      color: white;
      i {
        cursor: pointer;
      }
    }
  }

  .m-container__actions {
    @include felx-centered;
    position: relative;
    bottom: 0;

    button {
      font-size: 18px;
      color: white;
      background: $red;
      padding: 10px 20px;
      border-radius: $border-radius-md;
      margin: 10px;
      margin-bottom: 0;
    }
  }

</style>
