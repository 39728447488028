<template>
  <div>
    <div class="loading-component" v-if="fetchingPage">
      <fingerprint-spinner
        :animation-duration="1000"
        :size="150"
        :color="'#00b1ff'"
      ></fingerprint-spinner>
    </div>

    <div v-else class="p-6">
      <div
        class="page_name w-full flex justify-center content-center items-center mt-10"
      >
        <p class="text-2xl font-bold">{{ info.name }}</p>
        <div class="ml-4">
          <img
            class="h-16 rounded-full object-cover"
            :src="info.picture"
            :alt="info.name"
          />
        </div>
      </div>

      <div class="mt-12 mb-6">
        <CalendarHeatmap
          :values="repliersStat"
          :end-date="new Date()"
          tooltip-unit="repliers"
        ></CalendarHeatmap>
      </div>

      <div class="">
        <b-tabs type="is-boxed" v-model="activeTab" :destroy-on-hide="true">
          <b-tab-item label="Info" headerClass="font-bold text-blue-500">
            <pageInfo class="mt-8"></pageInfo>
          </b-tab-item>
          <b-tab-item label="Settings" headerClass="font-bold text-blue-500">
            <settings></settings>
          </b-tab-item>
          <b-tab-item label="Users" headerClass="font-bold text-blue-500">
            <template slot="header">
              <span>
                Users
                <b-tag rounded>
                  {{
                    Object.keys($store.getters["page/info"].users || {}).length
                  }}
                </b-tag>
              </span>
            </template>
            <users></users>
          </b-tab-item>
          <b-tab-item
            label="Subscription"
            headerClass="font-bold text-blue-500"
          >
            <subscription></subscription>
          </b-tab-item>
          <b-tab-item label="Posts" headerClass="font-bold text-blue-500">
            <posts></posts>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { FingerprintSpinner } from "epic-spinners";
import { SelfBuildingSquareSpinner } from "epic-spinners";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("page");
import { CalendarHeatmap } from "vue-calendar-heatmap";
import notificationModal from "./../components/notificationModal.vue";
import snackbar from "./utility components/snackbar.vue";
import pageInfo from "../components/page/info";
import subscription from "../components/page/subscription";
import settings from "../components/page/settings";
import users from "../components/page/users";
import posts from "../components/posts/posts"
export default {
  name: "Page_",
  components: {
    posts,
    FingerprintSpinner,
    SelfBuildingSquareSpinner,
    notificationModal,
    snackbar,
    pageInfo,
    CalendarHeatmap,
    settings,
    users,
    subscription
  },
  methods: {
    fetchPage(id) {
      this.fetchingPage = true;
      return this.$axios({
        url: `/pages/${id}`,
        method: "get",
        // use store to get access token
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      })
        .then(({ data }) => {
          this.$store.dispatch("page/set_page", data);
        })
        .catch(err => {
          // TODO show error mesage
          console.log(err);
        })
        .finally(() => {
          this.fetchingPage = false;
        });
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["info", "repliersStat"]),
    central_loading() {
      return this.$store.getters.central_loading;
    }
  },
  data() {
    return {
      activeTab: 0,
      fetchingPage: true
    };
  },
  created() {
    this.fetchPage(this.$route.params.id);
  }
};
</script>
<style lang="scss" scoped></style>
