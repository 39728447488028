import axios from "axios";
import Vue from "vue";
import { getAtPath } from "./../utils/get_at_path";

export default {
  namespaced: true,
  state: {
    info: null,
    repliersStat: null,
    services: null
  },
  mutations: {
    setInfo(state, payload) {
      state.info = payload;
    },
    setRepliersStat(state, payload) {
      state.repliersStat = payload;
    },
    setServices(state, payload) {
      state.services = payload;
    },
    change_plan(state, payload) {
      Vue.set(state.info, "package", payload);
    },
    resetPageInfo(state) {
      state.info = null;
      state.repliersStat = null;
      state.services = null;
    },
    set_validity(state, payload) {
      Vue.set(state.info, "valid", payload);
    }
  },
  actions: {
    set_page({ dispatch, commit, getters, rootGetters }, payload) {
      if (getAtPath(["page", "info"], payload)) {
        commit("setInfo", getAtPath(["page", "info"], payload));
        if (getAtPath(["page", "repliersStat"], payload)) {
          commit(
            "setRepliersStat",
            getAtPath(["page", "repliersStat"], payload)
          );
        }
        if (getAtPath(["page", "services"], payload)) {
          commit("setServices", getAtPath(["page", "services"], payload));
        }
      } else {
        // TODO set Error to something readable
      }
    },
    change_plan({ dispatch, commit, getters, rootGetters }, payload) {
      commit("change_plan", payload);
    },
    set_validity({ dispatch, commit, getters, rootGetters }, payload) {
      commit("set_validity", payload);
    }
  },
  getters: {
    info: (state, getters, rootState, rootGetters) => {
      return state.info;
    },
    repliersStat: (state, getters, rootState, rootGetters) => {
      return state.repliersStat;
    },
    services: (state, getters, rootState, rootGetters) => {
      return state.services;
    },
    users: (state, getters, rootState, rootGetters) => {
      return state.info.users.reduce((acc, user) => {
        acc[user.id.split("|")[1]] = user.name;
        return acc;
      }, {});
    },
    chatbot_info: (state, getters, rootState, rootGetters) => {
      return getAtPath(["info", "chatbot"], state);
    }
  }
};
