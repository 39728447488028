import axios from "axios";

// var instance = axios.create({
//   baseURL: 'https://mujeeb-api.herokuapp.com/api'
// })
// https://mujeeb-api-test.herokuapp.com/dashboard
// https://api.mujeeb.ai/api
// http://localhost:3000/dashboard
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, "$axios", { value: axios });
  }
};
