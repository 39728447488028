<template>
  <div class="page-review relative">
    <navigationHeader></navigationHeader>

    <notificationModal
      v-if="show_notification_modal"
      @close-modal="
        () => {
          this.show_notification_modal = false;
        }
      "
      :pageId="$route.params.id"
    ></notificationModal>

    <div class="loading-component" v-if="isFetchingPage">
      <fingerprint-spinner
        :animation-duration="1000"
        :size="150"
        :color="'#00b1ff'"
      ></fingerprint-spinner>
    </div>

    <div class="page-dashboard" v-else>
      <div class="repliers-heat-map">
        <CalendarHeatmap :values="pageInfo.repliersStat" :end-date="new Date()"  tooltip-unit="repliers" ></CalendarHeatmap>
      </div>

      <div
        class="m-field"
        v-for="(key, index) in Object.keys(pageProperties)"
        :key="index"
      >
        <p class="m-title">{{ properties[key] }}</p>
        <div class="m-action">
          <p v-if="key !== 'link'">{{ pageProperties[key] }}</p>
          <a
            v-else
            :href="pageInfo.pageData[key]"
            class="link"
            target="_blank"
            >{{ pageInfo.pageData[key] }}</a
          >
        </div>
      </div>

      <div class="m-field">
        <p class="m-title">Page Package</p>
        <div class="m-action">
          <b-dropdown
            v-model="selectedPagePackage"
            @change="confirmPackageChange"
          >
            <button class="button is-primary" slot="trigger">
              <span>{{ selectedPagePackage }}</span>
              <i class="ion-md-arrow-dropdown ml-2"></i>
            </button>
            <b-dropdown-item
              v-for="(p, index) in $store.getters.packages"
              :key="index"
              :value="p"
              >{{ p }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>

      <div class="m-field">
        <p class="m-title">Page Validity</p>
        <!-- <span class="spacer"></span> -->
        <div class="m-action">
          <div class="field">
            <b-switch
              v-model="pageInfo.pageValidity"
              @input="ConfirmChangePageValidityState"
            >
              {{ pageInfo.pageValidity }}
            </b-switch>
          </div>
        </div>
      </div>

      <div class="m-field">
        <p class="m-title">Page Credential:</p>
        <div class="m-action">
          <SelfBuildingSquareSpinner
            v-if="isFetchingPageCredentials"
            style="margin: auto auto;"
            :animation-duration="1000"
            :size="35"
            :color="'#00b1ff'"
          ></SelfBuildingSquareSpinner>
          <div v-else>
            <button
              v-if="credentialValidity === null"
              class="button"
              @click="getPageCredential"
            >
              Check Credentials
            </button>
            <p
              v-else
              :class="{
                'has-text-success': credentialValidity,
                'is-size-5': true,
                'has-text-danger': !credentialValidity
              }"
            >
              {{
                credentialValidity
                  ? "The access token is valid"
                  : "The access token is not valid"
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="m-field">
        <p class="m-title">Page Access Token:</p>
        <div class="m-action">
          <SelfBuildingSquareSpinner
            v-if="isFetchingAccessToken"
            style="margin: auto auto;"
            :animation-duration="1000"
            :size="35"
            :color="'#00b1ff'"
          ></SelfBuildingSquareSpinner>
          <div v-else>
            <button v-if="accessToken === ''" class="button" @click="getPageAT">
              Access token
            </button>
            <p v-else>{{ accessToken }}</p>
          </div>
        </div>
      </div>

      <div class="m-field">
        <p class="m-title">Page Notification:</p>
        <div class="m-action">
          <button
            class="button"
            @click="
              () => {
                this.show_notification_modal = !this.show_notification_modal;
              }
            "
          >
            Send a notification
          </button>
        </div>
      </div>

      <div>
        <div class="header-title">
          Services
        </div>
        <servicesController></servicesController>
      </div>

      <div v-if="chatbotService != null">
        <div class="header-title">
          Chatbot
        </div>

        <div class="chat-bot-section">
          <div class="m-field">
            <p class="m-title">chatbot package:</p>
            <div class="m-action">
              <b-dropdown
                v-model="chatbotSelectedPackage"
                @change="confirmchtbotPackageChange"
              >
                <button class="button is-primary" slot="trigger">
                  <span>{{ chatbotSelectedPackage }}</span>
                  <i class="ion-md-arrow-dropdown ml-2"></i>
                </button>
                <b-dropdown-item
                  v-for="(p, index) in chatBotPackages"
                  :key="index"
                  :value="p"
                  >{{ p }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>

          <div class="m-field">
            <p class="m-title">chatbot creation date:</p>
            <div class="m-action">
              <p>{{ chatbotService.created_at }}</p>
              <!-- <button class="button" @click="() => { this.show_notification_modal = !this.show_notification_modal }">Send a notification</button> -->
            </div>
          </div>
        </div>
      </div>

      <div class="header-title">
        Users
      </div>

      <div
        class="user-container"
        v-for="(value, key) in pageInfo.users"
        :key="key"
      >
        <div class="m-field">
          <p class="m-title">Name</p>
          <!-- <span class="spacer"></span> -->
          <div class="m-action">
            <p>{{ value.name }}</p>
          </div>
        </div>

        <div class="m-field">
          <p class="m-title">User Credential:</p>
          <div class="m-action">
            <SelfBuildingSquareSpinner
              v-if="
                isFetchingCredentialsForUser &&
                  fetchingForUser === value.id &&
                  userCredentialValidity === null
              "
              style="margin: auto auto;"
              :animation-duration="1000"
              :size="35"
              :color="'#00b1ff'"
            ></SelfBuildingSquareSpinner>
            <div v-else>
              <button
                v-if="fetchingForUser !== value.id"
                class="button"
                @click="checkUserCredential(value)"
              >
                Check Credentials
              </button>
              <p
                v-else-if="fetchingForUser === value.id"
                :class="{
                  'has-text-success': userCredentialValidity,
                  'is-size-5': true,
                  'has-text-danger': !userCredentialValidity
                }"
              >
                {{
                  userCredentialValidity
                    ? "The access token is valid"
                    : "The access token is not valid"
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="m-field">
          <p class="m-title">Use this user's access token</p>
          <div class="m-action">
            <button class="button" @click="useThisUserAccessToken(value)">
              Use this user's access token
            </button>
          </div>
        </div>
      </div>

      <div class="">

      </div>
    </div>

    <snackbar v-if="loading" :text="snackbarText"></snackbar>
  </div>
</template>

<script>
import { FingerprintSpinner } from "epic-spinners";
import { SelfBuildingSquareSpinner } from "epic-spinners";
import notificationModal from "./../components/notificationModal.vue";
import snackbar from "./utility components/snackbar.vue";
import servicesController from "./utility components/pageServices";
import { CalendarHeatmap } from "vue-calendar-heatmap";
import pageInfo from '../components/page/info'
import moment from "moment";
export default {
  components: {
    "fingerprint-spinner": FingerprintSpinner,
    SelfBuildingSquareSpinner,
    notificationModal,
    snackbar,
    servicesController,
    CalendarHeatmap,
    pageInfo
  },
  data() {
    const today = new Date()
    return {
      snackbarText: "",
      loading: false,
      show_notification_modal: false,
      pageData: null,
      isFetchingPage: false,
      selectedPagePackage: "",
      pageValidity: true,
      cancel: false,
      isFetchingPageCredentials: false,
      isFetchingCredentialsForUser: false,
      fetchingForUser: "",
      userCredentialValidity: null,
      isFetchingAccessToken: false,
      credentialValidity: null,
      accessToken: "",
      users: {},
      fetchingUsersData: false,
      properties: {
        name: "Page Name:",
        id: "Page Id:",
        link: "Page Link:",
        commenter_last_time_used: "Activiation Of The Last Commenter:",
        broadcast_last_time_used: "Activiation Of The Last Broadcast:",
        createdAt: "Page Created At:",
        numOfBroadcasts: "Number Of Broadcasts:",
        numOfCommenters: "Number Of Commenters:"
      },
      dataSet: [
        {
          name: "number of commenters per month",
          chartType: "bar",
          values: [25, 40, 30, 35, 8, 52, 17, -4]
        }
      ],
      chatbotService: null,
      pageInfo: null,
      cahtbotPackagesRequestOptions: {
        method: "get",
        baseURL:
          "https://mujeeb-api-test.herokuapp.com/api/services/chatbot/packages",
        headers: {
          Authorization: `Bearer ${this.$auth.accessToken}`
        },
        responseType: "json"
      },
      chatBotPackages: null,
      chatbotSelectedPackage: null
    };
  },
  computed: {
    pageProperties() {
      const dateProperty = [
        "createdAt",
        "commenter_last_time_used",
        "broadcast_last_time_used"
      ];
      return Object.keys(this.pageInfo.pageData).reduce((acc, key) => {
        if (Object.keys(this.properties).includes(key)) {
          if (dateProperty.includes(key)) {
            acc[key] = `${moment
              .utc(this.pageInfo.pageData[key])
              .format("MMM D YYYY")} | ${moment
              .utc()
              .diff(moment.utc(this.pageInfo.pageData[key]), "days")} days`;
          } else {
            acc[key] = this.pageInfo.pageData[key];
          }
        }
        return acc;
      }, {});
    }
  },
  async created() {
    // call the server to get the page info
    this.isFetchingPage = true;
    return (
      Promise.all([
        this.$store.dispatch("fetchPage", {
          accessToken: this.$auth.accessToken,
          id: this.$route.params.id
        }),
        this.$axios(this.cahtbotPackagesRequestOptions)
      ])
        // eslint-disable-next-line no-unused-vars
        .then(([_, { data }]) => {
          this.chatBotPackages = data.packages;
          this.pageInfo = this.$store.getters["pageInfo"];
          this.chatbotService = this.$store.getters["services"].chatbot;
          this.selectedPagePackage = this.pageInfo.originalPackage;
          if (this.chatbotService) {
            this.chatbotSelectedPackage = this.chatbotService.package;
          }
        })
        .catch(error => {
          this.fetchingUsersData = false;
          this.isFetchingPage = false;
          console.log(error);
        })
        .finally(() => {
          this.isFetchingPage = false;
        })
    );
  },
  watch: {
    "$store.getters.packages": {
      immediate: true,
      handler: function(newVal) {
        console.log("inside the watcher");
        console.log(newVal);
        if (newVal.length === 0) {
          console.log("fetching the packages");
          this.$store.dispatch("fetchPackages", {
            accessToken: this.$auth.accessToken
          });
        }
      }
    }
  },
  methods: {
    confirmPackageChange(selectedPackage) {
      console.log("confirm");
      console.log(selectedPackage);
      const that = this;
      this.$dialog.confirm({
        title: "Changing the package",
        message: `Be careful, you are about to change the package of this page to ${selectedPackage}`,
        cancelText: "Cancel",
        confirmText: "Change",
        type: "is-success",
        onConfirm: () => {
          this.changePagePackage(selectedPackage);
        },
        onCancel: () => {
          this.selectedPagePackage = this.pageInfo.originalPackage;
        }
      });
    },
    confirmchtbotPackageChange(selectedPackage) {
      console.log("confirm");
      console.log(selectedPackage);
      this.$dialog.confirm({
        title: "Changing the package",
        message: `Be careful, you are about to change the package of this chatbot to ${selectedPackage}`,
        cancelText: "Cancel",
        confirmText: "Change",
        type: "is-success",
        onConfirm: () => {
          this.changeChatbotPackage(selectedPackage);
        },
        onCancel: () => {
          this.chatbotSelectedPackage = this.chatbotService.package;
          console.log("cancel");
        }
      });
    },
    changeChatbotPackage(selectedPackage) {
      const options = {
        url: `/pages/${this.$route.params.id}/chatbot/package`,
        method: "put",
        headers: {
          Authorization: `Bearer ${this.$auth.accessToken}`
        },
        data: {
          new_package: selectedPackage
        },
        responseType: "json"
      };
      this.$axios(options)
        .then(({ data }) => {
          if (data.success) {
            console.log("success");
          } else {
            this.notifyUser();
          }
        })
        .catch(error => {
          this.notifyUser();
        });
    },
    changePagePackage(selectedPackage) {
      const options = {
        url: `/pages/${this.$route.params.id}/package`,
        method: "post",
        headers: {
          Authorization: `Bearer ${this.$auth.accessToken}`
        },
        data: {
          new_package: selectedPackage
        },
        responseType: "json"
      };
      this.$axios(options)
        .then(({ data }) => {
          if (data.success) {
            console.log("success");
          } else {
            this.notifyUser();
          }
        })
        .catch(error => {
          this.notifyUser();
        });
    },
    ConfirmChangePageValidityState(newState) {
      if (!this.cancel) {
        this.$dialog.confirm({
          title: "Changing the validity state",
          message: `Be careful, you are about to change the validity of this page to ${
            newState ? "valid" : "invalid"
          }`,
          cancelText: "Cancel",
          confirmText: "Change",
          type: "is-success",
          onConfirm: () => {
            this.changePageValidityState(newState);
          },
          onCancel: () => {
            this.cancel = true;
            this.pageValidity = !newState;
            let that = this;
            setTimeout(() => {
              that.cancel = false;
            }, 100);
          }
        });
      }
    },
    notifyUser(msg = "Something went wrong", type = "is-danger") {
      this.$toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    changePageValidityState(newState) {
      const options = {
        url: `/pages/${this.$route.params.id}/validity`,
        method: "post",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        data: {
          state: newState
        },
        responseType: "json"
      };
      console.log(options);
      this.$axios(options)
        .then(({ data }) => {
          this.cancel = false;
          if (data) {
            this.notifyUser("the state has changed", "is-success");
          } else {
            this.notifyUser();
            this.pageValidity = !newState;
          }
        })
        .catch(error => {
          this.cancel = false;
          this.notifyUser();
          this.pageValidity = !newState;
        });
    },
    getPageCredential() {
      this.isFetchingPageCredentials = true;
      const options = {
        url: `/info/pages/${this.$route.params.id}/credential/validity`,
        method: "get",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      };
      this.$axios(options)
        .then(({ data }) => {
          this.credentialValidity = data.validity;
          this.isFetchingPageCredentials = false;
        })
        .catch(error => {
          this.isFetchingPageCredentials = false;
          this.notifyUser();
        });
    },
    getPageAT() {
      this.isFetchingAccessToken = true;
      const options = {
        url: `/info/pages/${this.$route.params.id}/credential/accesstoken`,
        method: "get",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      };
      this.$axios(options)
        .then(({ data }) => {
          console.log(data);
          this.accessToken = data.page_access_token;
          this.isFetchingAccessToken = false;
        })
        .catch(error => {
          this.isFetchingAccessToken = false;
          console.log(error.response.data.message);
          this.notifyUser(error.response.data.message || null);
        });
    },
    formatUsersData(users) {
      if (!users || users.length === 0) {
        return false;
      }
      return users.reduce((acc, user) => {
        let userId = user.id.split("|")[1];

        this.$set(acc, userId, user);
        return acc;
      }, {});
    },
    checkUserCredential(user) {
      this.userCredentialValidity = null;
      this.isFetchingCredentialsForUser = false;
      this.fetchingForUser = "";

      const options = {
        url: `/info/users/${user.id}/pages/${
          this.$route.params.id
        }/accesstoken/validity`,
        method: "get",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      };
      let that = this;
      this.fetchingForUser = user.id;
      this.isFetchingCredentialsForUser = true;
      this.$axios(options)
        .then(({ data }) => {
          console.log(data);
          // this.isFetchingCredentialsForUser = false
          // this.fetchingForUser = ''
          this.userCredentialValidity = data.validity;
        })
        .catch(error => {
          console.log(error);
          this.isFetchingCredentialsForUser = false;
          this.fetchingForUser = "";
        });
    },
    useThisUserAccessToken(user) {
      this.loading = true;
      this.snackbarText = "Changing the current access token...";
      const options = {
        url: `/info/users/${user.id}/pages/${
          this.$route.params.id
        }/accesstoken`,
        method: "post",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      };
      this.$axios(options)
        .then(({ data }) => {
          console.log(data);
          this.snackbarText = "The access token has been changed";
        })
        .catch(error => {
          console.log(error);
          this.snackbarText = "An error has occurred";
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.snackbarText = "";
          }, 5000);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-review {
  word-break: break-all;
  min-height: 100vh;
  min-width: 300px;
  overflow-y: auto;
}

.page-dashboard {
  margin-top: 20px;
  margin-bottom: 50px;
}

.frappe-chart text.title {
  font-size: 15px !important;
}

.header-title {
  display: block;
  text-align: center;
  margin: 20px;
  font-size: 30px;
}

.user-container {
  border-bottom: 1px solid rgba(97, 97, 97, 0.274);
  &:last-child {
    border: none;
  }
  .user-checkbox {
    span {
      margin-left: 10px;
    }
  }
}

.repliers-heat-map {
  margin: 30px;
}
</style>
