<template>
  <div class="m-modal">
    <div class="m-container">
      <div class="m-modal__fields-container">
        <div v-if="current_step === 1">
          <div class="m-field">
            <b-field label="Notification Type">
              <b-select
                placeholder="Type of notification"
                v-model="selected_notification_type"
                expanded
              >
                <option
                  v-for="(type, index) in notification_types"
                  :value="type"
                  :key="index"
                >
                  {{ type }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="m-field">
            <b-field label="notification Title">
              <b-input v-model="notification_title" expanded></b-input>
            </b-field>
          </div>

          <div class="m-field">
            <!-- <b-field label="notification Details">
                        <b-input type="textarea" v-model="notification_details"></b-input>
                    </b-field> -->
            <vue-editor id="editor" v-model="notification_details"></vue-editor>
          </div>

          <div class="m-field">
            <b-checkbox
              v-model="urlType"
              true-value="in-site-URL"
              false-value="URL"
            >
              {{ urlType }}
            </b-checkbox>
          </div>

          <div class="m-field" v-if="urlType === 'in-site URL'">
            <b-field label="In-site URL">
              <b-select placeholder="In-site URL" v-model="notification_URL">
                <option
                  v-for="(value, key) in notificationTypes"
                  :value="value"
                  :key="value"
                >
                  {{ key }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="m-field" v-if="urlType === 'URL'">
            <b-field label="Outside URL">
              <b-input v-model="notification_URL"></b-input>
            </b-field>
          </div>
        </div>

        <div v-else class="notification-preview">
          <div class="m-field">
            {{ selected_notification_type }}
          </div>

          <div class="m-field">
            {{ notification_title }}
          </div>

          <div class="m-field">
            {{ notification_details }}
          </div>

          <div class="m-field">
            {{ notification_URL }}
          </div>
        </div>
      </div>

      <div class="m-modal__action">
        <button class="button is-danger" @click="$emit('close-modal')">
          Cancel
        </button>
        <button
          class="button is-success"
          @click="confirm"
          :disabled="sending_notification"
        >
          {{ current_step === 1 ? "next" : "send" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import moment from "moment";
export default {
  props: ["pageId"],
  components: {
    VueEditor
  },
  data() {
    return {
      current_step: 1,
      notification_types: ["danger", "warn", "success", "dark"],
      selected_notification_type: "danger",
      notification_title: "",
      notification_details: "",
      notification_URL: "",
      sending_notification: false,
      notificationTypes: {},
      insite_url: "",
      urlType: "in-site-URL"
    };
  },
  methods: {
    confirm() {
      if (this.current_step === 1) {
        if (this.notification_title === "") {
          this.$buefy.toast.open({
            duration: 2000,
            message: "Notification title is required",
            position: "is-top",
            type: "is-danger"
          });
          return;
        }
        this.current_step = 2;
      } else {
        let notification_Data = {
          date: moment()
            .utc()
            .valueOf(),
          type: this.selected_notification_type,
          title: this.notification_title,
          details: this.notification_details,
          urlType: this.urlType,
          url: this.notification_URL
        };
        notification_Data = Object.keys(notification_Data).reduce((acc, p) => {
          let val = notification_Data[p];
          if (val) {
            acc[p] = val;
          }
          return acc;
        }, {});
        notification_Data.dismissed = false;
        notification_Data.show = true;
        const options = this.$route.params.id
          ? {
              url: `/notifications/pages/${this.$route.params.id}/notification`,
              baseURL: process.env.VUE_APP_OLD_BASE_URL,
              method: "post",
              headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
              data: {
                notification: {
                  ...notification_Data
                }
              },
              responseType: "json"
            }
          : this.$store.getters.pagesToBeChanges.length > 0
          ? {
              url: `/pages/notifications`,
              baseURL: process.env.VUE_APP_OLD_BASE_URL,
              method: "post",
              headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
              data: {
                notification: {
                  ...notification_Data
                },
                idOfPages: this.$store.getters.pagesToBeChanges.map(
                  ({ id }) => id
                )
              },
              responseType: "json"
            }
          : {
              url: `/notifications`,
              baseURL: process.env.VUE_APP_OLD_BASE_URL,
              method: "post",
              headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
              data: {
                notification: {
                  ...notification_Data
                }
              },
              responseType: "json"
            };
        this.sending_notification = true;
        console.log(options);
        this.$axios(options)
          .then(({ data }) => {
            console.log(data);
            if (data.success) {
              this.$buefy.toast.open({
                duration: 3000,
                message: "Page has been notified.",
                position: "is-top",
                type: "is-success"
              });
              this.sending_notification = false;
              this.$emit("close-modal");
            }
          })
          .catch(error => {
            console.log(error);
            this.$buefy.toast.open({
              duration: 3000,
              message: "Something went wrong",
              position: "is-top",
              type: "is-danger"
            });
            this.sending_notification = false;
          });
      }
    }
  },
  mounted() {
    const options = {
      url: `/notifications/types`,
      baseURL: process.env.VUE_APP_OLD_BASE_URL,
      method: "get",
      headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
      responseType: "json"
    };
    this.$axios(options)
      .then(({ data }) => {
        console.log(data);
        this.notificationTypes = data;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        // console.log('finally')
      });
  }
};
</script>

<style lang="scss" scoped>
.m-container {
  width: 90%;
  .m-field {
    .field {
      width: 100%;
    }
  }
  .quillWrapper {
    width: 100%;
  }
}
// .container {
//     display: inline-flex;
// }

.notification-preview {
  .m-field {
    border-bottom: 1px solid rgb(212, 212, 212);
  }
}
</style>
