import axios from "axios";

export default {
  /**
   * @function fetchpages
   * @description get the pages from the database.
   * @param {Object} context the store context.
   * @param {Object} context.commit the commit object.
   * @param {Object} context.state the store state object.
   * @param {Object} payload object contains the accessToken.
   * @param {string} payload.accessToken the user access token.
   */
  fetchPages({ commit, state }, payload) {
    commit("setIsFetchingPages", true);
    const options = {
      url: "/pages",
      baseURL: process.env.VUE_APP_OLD_BASE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${payload.accessToken}`
      },
      params: {
        pagination: "firstTime",
        referenceValue: state.lastInPage,
        limit: state.limit,
        criterion: state.criterion,
        _package: state.selectedPackage,
        validity: state.validityState
      },
      responseType: "json"
    };
    if (state.useQuery) {
      console.log("using query");
      options.params = { ...state.query, ...options.params };
      options.url = "/query";
    }
    console.log(options);
    axios(options)
      .then(({ data }) => {
        commit("setPages", data.pages);
        // commit("setfirstInPage", data.pages[0][state.criterion]);
        commit("setfirstInPage");
        // commit(
        //   "setlastInPage",
        //   data.pages[data.pages.length - 1][state.criterion]
        // );
        commit("setlastInPage");
        commit("setIsFetchingPages", false);
      })
      .catch(error => {
        commit("setIsFetchingPages", false);
        commit("setError", true);
        setTimeout(function() {
          commit("setError", false);
        }, 3000);
      });
  },
  /**
   * @function nextPages
   * @description get the pages from the database.
   * @param {Object} context the store context.
   * @param {Object} context.commit the commit object.
   * @param {Object} context.state the store state object.
   * @param {Object} payload object contains the accessToken.
   * @param {string} payload.accessToken the user access token.
   */
  nextPages({ commit, state }, payload) {
    commit("setIsFetchingPages", true);
    const options = {
      url: "/pages",
      baseURL: process.env.VUE_APP_OLD_BASE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${payload.accessToken}`
      },
      params: {
        pagination: "next",
        referenceValue: state.lastInPage,
        limit: state.limit,
        criterion: state.criterion,
        _package: state.selectedPackage,
        validity: state.validityState
      },
      responseType: "json"
    };
    if (state.useQuery) {
      options.params = { ...state.query, ...options.params };
      options.url = "/query";
    }
    console.log(options);
    axios(options)
      .then(({ data }) => {
        commit("setIsFetchingPages", false);
        if (data.pages && data.pages.length > 0) {
          commit("setPages", data.pages);
          // commit("setfirstInPage", data.pages[0][state.criterion]);
          commit("setfirstInPage");
          // commit(
          //   "setlastInPage",
          //   data.pages[data.pages.length - 1][state.criterion]
          // );
          commit("setlastInPage");
        } else {
          commit("setEndOfTheList", true);
        }
      })
      .catch(error => {
        commit("setIsFetchingPages", false);
        commit("setError", true);
        setTimeout(function() {
          commit("setError", false);
        }, 3000);
      });
  },
  /**
   * @function previousPage
   * @description get the pages from the database.
   * @param {Object} context the store context.
   * @param {Object} context.commit the commit object.
   * @param {Object} context.state the store state object.
   * @param {Object} payload object contains the accessToken.
   * @param {string} payload.accessToken the user access token.
   */
  previousPage({ commit, state }, payload) {
    commit("setIsFetchingPages", true);
    const options = {
      url: "/pages",
      baseURL: process.env.VUE_APP_OLD_BASE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${payload.accessToken}`
      },
      params: {
        pagination: "previous",
        referenceValue: state.firstInPage,
        limit: state.limit,
        criterion: state.criterion,
        _package: state.selectedPackage,
        validity: state.validityState
      },
      responseType: "json"
    };
    if (state.useQuery) {
      options.params = { ...state.query, ...options.params };
      options.url = "/query";
    }
    console.log(options);
    axios(options)
      .then(({ data }) => {
        commit("setIsFetchingPages", false);
        if (data.pages && data.pages.length > 0) {
          commit("setPages", data.pages);
          // commit("setfirstInPage", data.pages[0][state.criterion]);
          commit("setfirstInPage");
          // commit(
          //   "setlastInPage",
          //   data.pages[data.pages.length - 1][state.criterion]
          // );
          commit("setlastInPage");
        } else {
          commit("setEndOfTheList", true);
        }
      })
      .catch(error => {
        commit("setIsFetchingPages", false);
        commit("setError", true);
        setTimeout(function() {
          commit("setError", false);
        }, 3000);
      });
  },
  fetchPackages({ commit }, payload) {
    const options = {
      url: "/palns",
      method: "get",
      headers: {
        Authorization: `Bearer ${payload.accessToken}`
      },
      responseType: "json"
    };
    axios(options)
      .then(({ data }) => {
        if (data) {
          commit("setPackages", data);
        }
      })
      .catch(error => {
        commit("setError", true);
        setTimeout(function() {
          commit("setError", false);
        }, 3000);
      });
  },
  fetchPagesInsights({ commit }, payload) {
    const options = {
      url: "/statistics/pages",
      baseURL: process.env.VUE_APP_OLD_BASE_URL,
      method: "get",
      headers: {
        Authorization: `Bearer ${payload.accessToken}`
      },
      responseType: "json"
    };
    axios(options)
      .then(({ data }) => {
        if (data) {
          commit("setInsights", data);
        }
      })
      .catch(error => {
        commit("setError", true);
        setTimeout(function() {
          commit("setError", false);
        }, 3000);
      });
  },
  // TODO delete this action
  fetchPage({ commit }, payload) {
    commit("resetPageInfo");
    return axios({
      url: `/info/pages/${payload.id}`,
      method: "get",
      headers: { Authorization: `Bearer ${payload.accessToken}` },
      responseType: "json"
    })
      .then(({ data }) => {
        if (data) {
          let services = null;
          let page = {};
          if (data.page.services) {
            // commit('setPageServices', data.page.services)
            services = data.page.services;
          }
          page.pageData = data.page.info;
          page.selectedPackage = page.pageData.package;
          page.originalPackage = page.pageData.package;
          page.pageValidity = page.pageData.valid;
          page.users = {}
          page.pageData.users.map(user => {
            let userId = user.id.split("|")[1];
            user.id = userId;
            page.users[userId] = user;
          });
          page.services = services;
          page.repliersStat = data.page.repliersStat;
          commit("setPageInfo", page);
        }
      })
      .catch(error => {
        this.fetchingUsersData = false;
        this.isFetchingPage = false;
        console.log(error);
      });
  }
};
