<template>
    <nav class="flex items-center bg-blue-mujeeb p-4">
      <div class="">
        <span class="font-semibold text-xl tracking-tight text-white">Mujeeb</span>
      </div>
      <span class="spacer"></span>
      <button @click="downloadCSV" class="font-bold inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal hover:bg-white no-underline mr-2"><i class="ion-ios-cloud-download mr-2"></i>CSV</button>
      <button class="font-bold inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-red hover:bg-white no-underline" @click="$auth.logout()">Log out</button>
    </nav>
</template>

<script>
import contentDisposition from 'content-disposition';
export default {
  name: "avigation-header",
  methods: {
    notifyUser (msg='Something went wrong', type='is-danger') {
      this.$toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: type
      })
    },
    downloadCSV () {
      const options = {
        url: `/info-as-csv`,
        method: 'get',
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: 'blob'
      }
      this.$axios(options)
        .then((response) => {
          if (! response.data) {
            // show some notification
            return
          }
          const headers = response.headers
          if (!headers || !headers['content-disposition']) {
            // show some notification
            return
          }
          const contentDispositionHeader = headers['content-disposition']
          // const fileName = contentDispositionHeader.split('"')[1]

          // open the url to download the file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', contentDisposition.parse(contentDispositionHeader).parameters.filename); //or any other extension
          document.body.appendChild(link);
          link.click();

        })
        .catch(error => {
          console.log(error)
          this.notifyUser()
        })
    }
  }
};
</script>

<style>
</style>
