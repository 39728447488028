<template>
  <div class="section-container" >
    
    <div v-if="!loading">
      <div class="m-field" v-if="services.commenter != null">
        <p class="m-title">Commenter</p>
        <div class="m-action">
          <div class="field">
            <b-switch v-model="services.commenter.valid" @input="ConfirmChangePageValidityState('commenter')">
              {{ services.commenter.valid }}
            </b-switch>
          </div>
        </div>
      </div>

      <div class="m-field" v-if="services.chatbot != null">
        <p class="m-title">Chatbot</p>
        <div class="m-action">
          <div class="field">
            <b-switch v-model="services.chatbot.valid" @input="ConfirmChangePageValidityState('chatbot')">
              {{ services.chatbot.valid }}
            </b-switch>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div v-else>LOADING</div>
  </div>
</template>

<script>
export default {
  name: 'pageServices',
  data () {
    return {
      services: this.$store.getters['pageInfo'].services,
      loading: false
    }
  },
  methods: {
    ConfirmChangePageValidityState (service) {
      this.$dialog.confirm({
        title: `Changing the state of ${service}`,
        message: `Be careful, you are about to change the validity of this service`,
        cancelText: 'Cancel',
        confirmText: 'Change',
        type: 'is-success',
        canCancel: true,
        onConfirm: () => {
          this.changePageValidityState(service);
        },
        onCancel: () => {
          this.services[service].valid = !this.services[service].valid;
        }
      })
    },
    changePageValidityState (service) {
      this.$axios({
        // url: `/info/pages/${this.$route.params.id}`,
        url: `/info/pages/${this.$route.params.id}/services/${service}`,
        method: "post",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json",
        data: {
          value: this.services[service].valid
        }
      })
        .then(({ data }) => {
          console.log(data)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
