<template>
  <div
    v-if="fetching_posts"
    class="flex justify-center items-center align-items pt-6"
  >
    <SpringSpinner
      :animation-duration="2000"
      :size="120"
      :color="'#00b1ff'"
    ></SpringSpinner>
  </div>
  <div v-else>
    <div class="flex flex-wrap align-center items-center justify-center">
      <post v-for="post in posts" :key="post.postId" :post_data="post"></post>
    </div>
    <div class="flex justify-center mt-16 mb-20">
      <b-button class="mr-2 ml-2" v-if="canPagingForward" @click="next">أقدم</b-button>
      <b-button class="mr-2 ml-2" v-if="canPagingBackward" @click="back">أحدث</b-button>
    </div>
  </div>
</template>

<script>
import post from "./post";
import { SpringSpinner } from "epic-spinners";

export default {
  components: {
    post,
    SpringSpinner
  },
  data() {
    return {
      limitTo: 10,
      posts: [],
      fetching_posts: false,
      // pagination
      next_referenceValue: null,
      back_referenceValue: null,
      old_next_referenceValue: null,
      old_back_referenceValue: null,
      old_action: null,
      canPagingForward: true,
      canPagingBackward: false,
      stayOnSamePage: false
    };
  },
  methods: {
    // padination
    next() {
      this.fetchPosts("next");
      this.old_next_referenceValue = this.next_referenceValue;
      this.canPagingBackward = true;
      this.old_action = "next";
    },
    back() {
      this.fetchPosts("back");
      this.old_back_referenceValue = this.back_referenceValue;
      this.canPagingForward = true;
      this.old_action = "back";
    },
    fetchPosts(action = "next", reference) {
      this.fetching_posts = true;
      return this.$axios({
        url: `/posts`,
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        method: "get",
        params: {
          page_id: this.$route.params.id,
          action: action,
          referenceValue: reference
            ? reference
            : this.stayOnSamePage
            ? this.old_action === "next"
              ? this.old_next_referenceValue
              : this.old_back_referenceValue
            : action === "next"
            ? this.next_referenceValue
            : this.back_referenceValue
        },
        responseType: "json"
      })
        .then(({ data }) => {
          if (data.replies && data.replies.length > 0) {
            this.canPagingForward = data.canPagingforward;
            this.canPagingBackward = data.canPagingBackward;
            const thereIsStillPosts = data.replies.length === this.limitTo + 1;
            console.log(data.replies);
            if (thereIsStillPosts) {
              this.posts = data.replies.slice(0, this.limitTo);
              this.next_referenceValue = data.replies[this.limitTo].date;
            } else {
              this.posts = data.replies;
              this.next_referenceValue = null;
            }
            this.back_referenceValue = data.replies[0].date;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.fetching_posts = false;
        });
    }
  },
  created() {
    this.fetchPosts();
  }
};
</script>
<style lang="scss" scoped></style>
