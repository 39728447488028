<template>
  <div class="">
    <div class="w-full">
      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>id:</p>
        </div>
        <div class="">
          <p>{{ info.id }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>created at:</p>
        </div>
        <div class="">
          <p>{{ info.createdAt | readableDate }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>link:</p>
        </div>
        <div class="">
          <a
            :href="`https://fb.com/${info.id}`"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="icon ion-ios-link"></i
          ></a>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>database:</p>
        </div>
        <div class="">
          <a
            :href="`https://console.firebase.google.com/project/mujeeb-server/database/mujeeb-server/data/pages/${info.id}`"
            target="_blank"
            rel="noopener noreferrer"
            ><i class="icon ion-md-flame"></i>
          </a>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>last use date:</p>
        </div>
        <div class="">
          <p>{{ info.commenter_last_time_used | readableDate }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p># commenters:</p>
        </div>
        <div class="">
          <p>{{ info.numOfCommenters }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>package:</p>
        </div>
        <div class="">
          <p>{{ info.package }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>valid:</p>
        </div>
        <div class="">
          <div
            class="rounded pl-3 pr-3 text-white"
            :class="{
              'background-red': !info.valid,
              'background-green': info.valid
            }"
          >
            <p>{{ info.valid }}</p>
          </div>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>access token:</p>
        </div>
        <div class="">
          <div
            class="rounded pl-3 pr-3 text-white"
            :class="{
              'background-red':
                !loading_states.fetching_validity_state &&
                !accessToken_validity,
              'background-green':
                !loading_states.fetching_validity_state && accessToken_validity
            }"
          >
            <div v-if="loading_states.fetching_validity_state">
              <spring-spinner
                :animation-duration="2000"
                :size="20"
                :color="'#00b1ff'"
              ></spring-spinner>
            </div>
            <p v-else>{{ accessToken_validity ? "valid" : "NOT valid" }}</p>
          </div>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>messages in queue:</p>
        </div>
        <div class="">
          <p>{{ messages_in_queue }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>comments in queue:</p>
        </div>
        <div class="">
          <p>{{ comments_in_queue }}</p>
        </div>
      </div>

      <div
        class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
      >
        <div class="font-bold">
          <p>whose access token:</p>
        </div>
        <div class="">
          <div class="rounded pl-3 pr-3">
            <div v-if="loading_states.whos_access_token">
              <spring-spinner
                :animation-duration="2000"
                :size="20"
                :color="'#00b1ff'"
              ></spring-spinner>
            </div>
            <p v-else>{{ owner_of_access_token }}</p>
          </div>
        </div>
      </div>

      <chatbot v-if="chatbot_Exists"></chatbot>
      
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("page");
import { SpringSpinner } from "epic-spinners";
import chatbot from './info_uitls/chatbot'
import moment from "moment";
export default {
  components: {
    SpringSpinner,
    chatbot
  },
  data() {
    return {
      loading_states: {
        fetching_validity_state: true,
        fetching_pages_queue_lengths: false,
        whos_access_token: false
      },
      accessToken_validity: null,
      messages_in_queue: 0,
      comments_in_queue: 0,
      owner_of_access_token_id: null
    };
  },
  computed: {
    ...mapGetters(["info", "repliersStat"]),
    owner_of_access_token() {
      return this.$store.getters["page/users"][this.owner_of_access_token_id];
    },
    chatbot_Exists() {
      return this.$store.getters["page/chatbot_info"];
    }
  },
  filters: {
    readableDate: function(someDate) {
      if (someDate) {
        return `${moment
          .utc(someDate)
          .format("MMM D YYYY")} | ${moment
          .utc()
          .diff(moment.utc(someDate), "days")} days`;
      } else {
        return "None";
      }
    }
  },
  methods: {
    check_creditials_validity() {
      this.loading_states.fetching_validity_state = true;
      this.$axios({
        url: `/pages/${this.$route.params.id}/credentials/validity`,
        method: "get",
        headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
        responseType: "json"
      })
        .then(({ data }) => {
          console.log(data);
          this.accessToken_validity = data.validity;
        })
        .catch(error => {
          // ToDo show error message fot the user
          let message = error;
          if (error.response != null) {
            message = error.response.data;
          }
          console.log(error);
          this.$buefy.toast.open({
            duration: 4000,
            message: `${message}`,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading_states.fetching_validity_state = false;
        });
    }
  },
  created() {
    this.check_creditials_validity();
    this.loading_states.fetching_pages_queue_lengths = true;
    this.$axios({
      method: "get",
      url: `/pages/${this.$route.params.id}/queues`,
      headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
      responseType: "json"
    })
      .then(({ data }) => {
        this.messages_in_queue = data.private_replies_queue_length;
        this.comments_in_queue = data.comments_queue_length;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading_states.fetching_pages_queue_lengths = false;
      });
    this.loading_states.whos_access_token = true;
    this.$axios({
      method: "get",
      url: `/pages/${this.$route.params.id}/active-access-token`,
      headers: { Authorization: `Bearer ${this.$auth.accessToken}` },
      responseType: "json"
    })
      .then(({ data }) => {
        this.owner_of_access_token_id = data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading_states.whos_access_token = false;
      });
  }
};
</script>
<style lang="scss" scoped>
.ion-ios-link {
  font-size: 20px;
}
</style>
