<template>
  <div class="flex content-center justify-center flex-col">
    <div
      class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
    >
      <div class="font-bold">
        <p>renewal date:</p>
      </div>
      <div class="">
        <p>{{ renewal_date | readableDate }}</p>
      </div>
    </div>

    <div
      class="flex justify-between content-center items-center p-3 shadow rounded mb-2"
    >
      <div class="font-bold">
        <p>account manager:</p>
      </div>
      <div class="">
        <p>{{ manager.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      renewal_date: new Date(),
      note: "",
      managers: [{ name: "salem", id: 123 }, { name: "ahmad", id: 153 }],
      manager: { name: "salem", id: 123 }
    };
  },
  filters: {
    readableDate: function(someDate) {
      if (someDate) {
        return `${moment.utc(someDate).format("MMM D YYYY")}`;
      } else {
        return "None";
      }
    }
  }
};
</script>

<style></style>
